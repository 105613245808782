*{
    margin: 0;
    padding: 0;
}
body{
    background-image: url("./assets/bettlenutfarming.jpg");
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
    background-color: rgba(255, 255, 255, 0.5);
    opacity: 0.9;
}
.leftLeaf{
    width: 130px;
    height: 125px;
   
}

.BrandLogo{
    margin-top: 15px;
    width: 250px;
    height: 90px;
}
.RightLeaf{
    width: 130px;
    height: 125px;
}

.Plate-logo{
    animation: Plate-logo-spin infinite 20s linear;
    pointer-events: none;
    height: 40vmin;
}

@keyframes Plate-logo-spin {
    from{
        transform: rotate(0deg);
    }
    to{
        transform: rotate(360deg);
    }
    
}

.container {
    display: flex;
    cursor:grab;
    
  }

 a.active {
    color: #fff;
    background: #7600dc;
  }